import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { Touch } from 'vuetify/lib/directives'

Vue.use(Vuetify)

export default new Vuetify({
  directives: {
    Touch
  },
  // icons: {
  //   iconfont: 'md',
  // },
  theme: {
    dark: false,
  },
  themes: {
    light: {
		  primary: '#1976D2',
		  secondary: '#424242',
		  accent: '#82B1FF',
		  // error: '#FF5252',
		  error: '#FFC107',
		  info: '#2196F3',
		  success: '#4CAF50',
		  warning: '#FFC107'
	    },
	    dark: {
		  primary: '#1976D2',
		  secondary: '#424242',
		  accent: '#82B1FF',
		  // error: '#FF5252',
		  error: '#FFC107',
		  info: '#2196F3',
		  success: '#4CAF50',
		  warning: '#FFC107'
    },
  },
})
