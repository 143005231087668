import { Storage } from 'aws-amplify'

export default {
  // async put (imgName, base64Img) {

  //   const rtn = await Storage.put(imgName, 
  //     new Buffer(base64Img, 'base64'), {
  //       level: 'protected',
  //       contentType: 'image/jpeg'
  //     })
  //     .catch(e => console.log('storage.put',e))

  //   return rtn
  // },
  async put (imgName, imgBufferArray) {

    const rtn = await Storage.put(imgName, imgBufferArray, 
      {
        level: 'protected',
        contentType: 'image/jpeg'
      })
      .catch(e => console.log('storage.put',e))

    return rtn
  },
  async get (imgName) {
    const url = await Storage.get(imgName, {level: 'protected'})
      .catch(e => console.log('storage.get',e))

    return url
  }
}
