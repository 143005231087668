import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as gql from './appsync-gql'

export default {

  async getReservationsByUsername (username, currentDate) {
    return await API.graphql(
      graphqlOperation(gql.getReservationsByUsername, { username, currentDate })
    ).catch(e => {
      console.error('getReservationsByUsername', e)
      return e
    })
  },
  // async updateReservationStatus (input) {
  //   const expectedLastUpdateOn = input.lastUpdateOn
  //   input.lastUpdateOn = (new Date).toISOString()

  //   return await API.graphql(graphqlOperation(gql.updateReservationStatus, { input, expectedLastUpdateOn }))
  //     .catch(e => {
  //       console.error('updateReservationStatus', e)
  //       return e
  //     })
  // },
  async updateIdVerified (input) {
    return await API.graphql(
        graphqlOperation(gql.updateIdVerified, { input })
      ).catch(e => {
        console.error('updateIdVerified', e)
        return e
      })
  },
  async verifyMembers (input, hostVerifying) {
    return await API.graphql(
        graphqlOperation(gql.verifyMembers, { input, hostVerifying })
      ).catch(e => {
        console.error('verifyMembers', e)
        return e
      })
  },
  async getMembers (reservationCode) {
    return await API.graphql(
        graphqlOperation(
          gql.getMembers, { reservationCode }
        )
      )
      .catch(e => {
        console.error('getMembers', e)
        return e
      })
  },
  async createMember (input) {
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(graphqlOperation(gql.createMember, { input }))
      .catch(e => {
        console.error('createMember', e)
        return e
      })
  },
  async updateMember (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(graphqlOperation(gql.updateMember, { input, expectedLastUpdateOn }))
      .catch(e => {
        console.error('updateMember', e)
        return e
      })
  }
}