import appsync from '@/api/appsync'
import storage from '@/api/storage'

const state = {
  reservation: null,
  reservations: [],
  members: [],
  currentStep: 1,
  currentMember: null,
  guideUrl: null
}

const mutations = {
  setCurrentMember(state, val) {
    state.currentMember = val
  }, 
  setReservations(state, val) {
    state.reservations = val
  },  
  setReservation(state, val) {
    state.reservation = val
  },
  setMembers(state, val) {
    state.members = val
  },
  setCurrentStep(state, val) {
    state.currentStep = val
  },
  setGuideUrl(state, val) {
    state.guideUrl = val
  },
}

const actions = {

  async loadReservations ({ commit, dispatch, state, rootState }, val) {


    const rtn = await appsync.getReservationsByUsername(rootState.session.username, ((new Date).toISOString()).substring(0,10))

    console.log('getReservationsByUsername', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      if (rtn.data.getReservationsByUsername.length > 0) {

        const reservations = rtn.data.getReservationsByUsername.map(item => {
          // item.selectText = item.listingName + ' ' + item.checkInDate
          item.selectText = item.checkInDate
          return item
        })
        console.log('loadReservations', reservations)
        commit('setReservations', reservations)

        if (reservations.length === 1) {
          await dispatch('pickReservation', reservations[0])
        }

      } else {
        throw new Error(Exceptions.CustomNoReservation.message)
      }
    }
  },
  async pickReservation ({ commit, dispatch }, val) {

    commit('setReservation', val)
    await dispatch('loadMembers').catch(e => {
      console.error('loadMembers', e)
      throw e
    })
    
  },
  clearReservation ({ commit }) {

    commit('setReservation', undefined)
    
  },
  async verifyMembers ({ commit, state }, hostVerifying) {

    delete state.reservation.selectText
    delete state.reservation.notificationResponses

    const rtn = await appsync.verifyMembers(state.reservation, hostVerifying)

    console.log('verifyMembers', rtn)

    commit('setReservation', rtn.data.verifyMembers)
    
  },
  async loadMembers ({ commit, getters, state }) {

    const rtn = await appsync.getMembers(state.reservation.reservationCode)
    console.log('loadMembers rtn', rtn)
    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const members = rtn.data.getMembers

      if (members.length <= state.reservation.memberCount) {
        const existingMemberNos = members.map((item) => {
          return item.memberNo
        })

        let i = 1
        // console.log('i:', i)
        for (i; i <= state.reservation.memberCount; i++) {
          if (existingMemberNos.indexOf(i) === -1) {
            members.push({ 
              reservationCode: state.reservation.reservationCode, 
              memberNo: i, 
              similarity: 0,
              idVerified: false,
              idMatches: false,
              idImgSrc: require('@/assets/img/id.svg'),
              faceImgSrc: require('@/assets/img/face.svg'),
              idImgName: null,
              faceImgName: null,
              idImgKey: null,
              faceImgKey: null,
              // fullName: null, 
              // gender: null, 
              // birthDate: null, 
              // address: null, 
              // idNumber: null, 
              // nationality: null, 
              // occupation: null, 
              // roomNumber: null, 
            })
          }
        }
      }

      members.sort((a, b) => {
        if (a.memberNo < b.memberNo)
          return -1
        if (a.memberNo > b.memberNo)
          return 1
        return 0
      })

      const resultMembers = await Promise.all(members.map(async (item) => {
        if (item.idImgName) {
          item.idImgSrc = await storage.get(item.idImgName)
        } else {
          item.idImgSrc = require('@/assets/img/id.svg')
        }

        if (item.faceImgName) {
          item.faceImgSrc = await storage.get(item.faceImgName)
        } else {
          item.faceImgSrc = require('@/assets/img/face.svg')
        }

        return item

      }));

      commit('setMembers', resultMembers)
    }  

  },
  async saveMember ({ state, commit, dispatch, getters }, imageChanged) {
    console.log('begin state.currentMember', state.currentMember)
    console.log('begin imageChanged', imageChanged)

    if (state.currentMember.lastUpdateOn) {
      // console.log('updateMember', state.currentMember)
      const clonedCurrentMember = Object.assign({}, state.currentMember);
      delete clonedCurrentMember.idImgSrc
      delete clonedCurrentMember.faceImgSrc

      const rtn = await appsync.updateMember(clonedCurrentMember)

      if (rtn.errors) {
        throw rtn.errors[0]
      } else {
        // console.log('members', members)
        const member = rtn.data.updateMember

        const index = state.members[member.memberNo - 1]

        state.members[index] = member

        state.currentMember.lastUpdateOn = member.lastUpdateOn
      }
    } else {
      // const rtn = await appsync.createMember(state.currentMember)

      const clonedCurrentMember = Object.assign({}, state.currentMember);
      delete clonedCurrentMember.idImgSrc
      delete clonedCurrentMember.faceImgSrc

      const rtn = await appsync.createMember(clonedCurrentMember)
      
      if (rtn.errors) {
        throw rtn.errors[0]
      } else {
        // console.log('members', members)
        const member = rtn.data.createMember
        console.log('rtn.data.createMember', rtn.data.createMember)

        const index = state.members[member.memberNo - 1]

        state.members[index] = member

        console.log('end state.currentMember', state.currentMember)

        state.currentMember.lastUpdateOn = member.lastUpdateOn
      }
    }

    console.log('state.members', state.members.length)


    const allMembersInput = state.members.every(crtMember => {

      if (crtMember.faceImgKey) {
        if (crtMember.idImgKey) {
          return true
        }        
      }
      
      return false
      
    })

    console.log('allMembersInput', allMembersInput)

    if (allMembersInput) {
      await dispatch('verifyMembers', false)
    }
    
/*
    if (allMembersInput) {
      delete state.reservation.selectText
      delete state.reservation.notificationResponses

      if (imageChanged) {
        state.reservation.isIdVerified = false
      }

      console.log('reservation', state.reservation)

      const rtn = await appsync.updateIdVerified(state.reservation)
      if (rtn.errors) {
        throw rtn.errors[0]
      } else {
        const reservation = rtn.data.updateIdVerified
        commit('setReservation', reservation)
      }
    }
    */

  },
  async putImage ({ commit, getters, state, rootState}, { imgBufferArray, isId }) {
    state.currentMember.idMatches = false
    
    if (isId) {
      state.currentMember.idVerified = false
      state.currentMember.idImgName = `${state.reservation.reservationCode}/id/${state.currentStep}.jpg`
      state.currentMember.idImgKey = `protected/${rootState.session.identityId}/${state.reservation.reservationCode}/id/${state.currentStep}.jpg`

      await storage.put(state.currentMember.idImgName, imgBufferArray)

      state.currentMember.idImgSrc = await storage.get(state.currentMember.idImgName)
    } else {
      state.currentMember.faceImgName = `${state.reservation.reservationCode}/face/${state.currentStep}.jpg`
      state.currentMember.faceImgKey = `protected/${rootState.session.identityId}/${state.reservation.reservationCode}/face/${state.currentStep}.jpg`

      await storage.put(state.currentMember.faceImgName, imgBufferArray)

      state.currentMember.faceImgSrc = await storage.get(state.currentMember.faceImgName)
    }
  },

  setCurrentStep ({ commit, state }, val) {
    commit('setCurrentStep', val)
    commit('setCurrentMember', state.members[val - 1])
  }
  
}

const getters = {
    steps: state => {
      return state.members.length
    },
    currentStep: state => {
      return state.currentStep
    },
    // allIdVerified: state => {
    //   console.log('state.members.length', state.members.length)

    //   if (state.members.length > 0) {
    //     return state.members.every(crtMember => crtMember.idVerified)  
    //   } else {
    //     return false
    //   }
    // },
    allIdVerified: state => {

      if (state.reservation && state.reservation.isIdVerified) {
        return state.reservation.isIdVerified
      }
      return false
    },
    memberInfoFilled: state => {
      // console.log('state.currentMember', state.currentMember)
      if (state.currentMember) {
        if (!state.currentMember.fullName) {
          return false
        }

        if (!state.currentMember.gender) {
          return false
        }

        if (!state.currentMember.birthDate) {
          return false
        }

        if (!state.currentMember.address) {
          return false
        }

        if (!state.currentMember.idNumber) {
          return false
        }

        if (!state.currentMember.nationality) {
          return false
        }

        if (!state.currentMember.prevPlace) {
          return false
        }

        if (!state.currentMember.nextDest) {
          return false
        }

        return true
      } else {
        return false
      }
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}