import * as AmplifyModules from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

const state = {
  username: null,
  identityId: null,
}

const mutations = {
  setUsername(state, username) {
    state.username = username
  },
  setIdentityId(state, identityId) {
    state.identityId = identityId
  }
}

const actions = {
  async login({ dispatch }) {
    await dispatch('loadSessionInfo')

  },
  async logout({ commit, dispatch }) {
    await AmplifyModules.Auth.signOut()
    AmplifyEventBus.$emit('authState', 'signedOut')
  },
  clearSessionInfo({ commit }) {
    commit('setUsername', null)
    commit('setIdentityId', null)
  },
  async customLogin({ commit, dispatch }, loginParams) {
    await AmplifyModules.Auth.signIn(loginParams)
    AmplifyEventBus.$emit('authState', 'signedIn')

  },
  async loadSessionInfo({ commit }) {
    
    const [
      currentAuthenticatedUser, 
      // currentSession, 
      // currentUserCredentials, 
      currentUserInfo, 
      // currentUserPoolUser
    ] = 
      await Promise.all([
        AmplifyModules.Auth.currentAuthenticatedUser(), 
        // AmplifyModules.Auth.currentSession(),
        // AmplifyModules.Auth.currentUserCredentials(),
        AmplifyModules.Auth.currentUserInfo(),
        // AmplifyModules.Auth.currentUserPoolUser(),
      ])

    console.log('currentAuthenticatedUser', currentAuthenticatedUser)
    // console.log('currentSession', currentSession)
    // console.log('currentUserCredentials', currentUserCredentials)
    console.log('currentUserInfo', currentUserInfo)
    // console.log('currentUserPoolUser', currentUserPoolUser)

    commit('setUsername', currentAuthenticatedUser.username)
    commit('setIdentityId', currentUserInfo.id)

    return

  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}