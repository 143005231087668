import Vue from 'vue'
import Router from 'vue-router'
import { components, AmplifyEventBus } from 'aws-amplify-vue'
import store from '@/store'
// import About from '@/views/About.vue'
const About = () => import('@/views/About.vue')
// import PreCheckIn from '@/views/PreCheckIn.vue'
const PreCheckIn = () => import('@/views/PreCheckIn.vue')
// import Guide from '@/views/Guide.vue'
const Guide = () => import('@/views/Guide.vue')
// import Welcome from '@/views/Welcome.vue'
const Welcome = () => import('@/views/Welcome.vue')
// import MultiReservations from '@/views/MultiReservations.vue'
const MultiReservations = () => import('@/views/MultiReservations.vue')


import { CallDialog } from '@/utils'

Vue.use(Router)

AmplifyEventBus.$on('authState', async (state) => {
  // console.log('AmplifyEventBus.$on', state)

  if (state === 'signedOut'){
    console.log('signedOut')

    
    store.dispatch('session/clearSessionInfo')
    store.dispatch('checkIn/clearReservation')

    router.push({path: '/welcome'})
  } else if (state === 'signedIn') {
    console.log('signedIn')

    const error = await store.dispatch('session/login').catch(e => {
      console.log('loginError', e)
      return e
    })

    if (error) {
      window.alert(JSON.stringify(error))
      return await store.dispatch('session/logout').catch(e => console.log('logout!!!', e))
    }

    const reservationsError = await store.dispatch('checkIn/loadReservations').catch(e => {
      console.error(e)
      return e
    })

    if (reservationsError) {
      window.alert(reservationsError.message)
      return await store.dispatch('session/logout').catch(e => console.log('logout!!!', e))
    }


    if (store.state.checkIn.reservations.length > 1) {
      router.push({path: '/multi'})
    } else {
      console.log('allIdVerified', store.getters['checkIn/allIdVerified'])
      const allIdVerified = store.getters['checkIn/allIdVerified']

      if (allIdVerified) {
        router.push({path: '/guide'})
      } else {
        router.push({path: '/'})
      }

      
    }
  }
});

const router = new Router({
  // mode: 'hash',
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: { 
        requiresAuth: true, 
        requiresIdVerified: false,
      }
    },
    {
      path: '/',
      name: 'PreCheckIn',
      component: PreCheckIn,
      meta: { 
        requiresAuth: true, 
        requiresIdVerified: false,
      }
    },
    {
      path: '/guide',
      name: 'Guide',
      component: Guide,
      meta: { 
        requiresAuth: true, 
        requiresIdVerified: false,
      }

    },
/*    
    {
      path: '/auth',
      name: 'Authenticator',
      component: components.Authenticator
    },
    {
      path: '/confirmSignUp',
      name: 'ConfirmSignUp',
      component: components.ConfirmSignUp,
      props: {
        confirmSignUpConfig: {
          header: 'Confirm Sign Up'
        }
      }
    },
*/
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome
    },
    {
      path: '/multi',
      name: 'MultiReservations',
      component: MultiReservations,
      meta: { 
        requiresAuth: true, 
        requiresIdVerified: false
      }
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // console.log('to', to)
    // console.log("beforeEach store.state.checkIn", JSON.stringify(store.state.checkIn))
    // console.log("beforeEach store.state.session", JSON.stringify(store.state.session))

    const error = await store.dispatch('session/loadSessionInfo').catch(e => {
      console.error(e)
      return e
    })

    if (error) {
      return await store.dispatch('session/logout').catch(e => console.log('logout!!!', e))
    }

    const session = store.state.session
    const checkIn = store.state.checkIn

    if (!session.username || !session.identityId) {
      console.log("Session time-out. Please re-login.")

      return await store.dispatch('session/logout').catch(e => console.log('logout!!!', e))
    }

    if (!checkIn.reservation && checkIn.reservations.length < 1) {
      console.log("No reservations. Please re-login.")

      return await store.dispatch('session/logout').catch(e => console.log('logout!!!', e))
    }
  }

  return next()
})

router.beforeResolve(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresIdVerified)) {

    // const allMembersInput = store.state.checkIn.members.length === store.state.checkIn.reservation.memberCount
    const allIdVerified = store.state.checkIn.members.every(crtMember => crtMember.idVerified)

    // console.log('allMembersInput', allMembersInput)
    // console.log('allIdVerified', allIdVerified)

    if (!allIdVerified) {
      CallDialog({message: "Need the host to verify all the ID photos first."})  
      return next(false)
    } 
  }

  return next()
})

export default router