import { EventBus } from '@/eventBus'

const CallDialog = function (error) {

	if (error) {

		console.error('calldialog', JSON.stringify(error))

	  if (error.errorType === Exceptions.ChangedByOthers.errorType) {
	    showWarningDialog({
	      message: Exceptions.ChangedByOthers.errorType,
	      stack: Exceptions.ChangedByOthers.message
	    })
	  } else if (error.errorType === Exceptions.CustomNoReservation.errorType) {
	    showWarningDialog({
	      message: Exceptions.CustomNoReservation.message,
	      stack: error.message
	    })
	  } else {
	    showWarningDialog(error)
	  }

	}
}

const showWarningDialog = function (error) {

  EventBus.$emit('WarningDialog', { 
    showDialog: true, 
    errorType: error.errorType,
    errorMessage: error.message,
    errorStack: error.stack,
  })
}


const Exceptions = {
	ChangedByOthers: {
		errorType: "Session:Expired",
		message: "Session expired. Please re-login."
	},
	ChangedByOthers: {
		errorType: "DynamoDB:ConditionalCheckFailedException",
		message: "The data has been changed by another session. Please refresh or sign out."
	},
	CustomNoReservation: {
    	errorType: "DynamoDB:CustomNoData",
    	message: "There is no reservation available for this user."
	},
	CustomNoListing: {
    	errorType: "DynamoDB:CustomNoData",
    	message: "There is no listing available for this user."
	},
	CustomNotAllIdsRecognized: {
    	errorType: "Identity:NotRecognized",
    	message: "Need all of the IDs recognized."
	},
	CustomNotAllNamesSet: {
    	errorType: "MemberNames:NotSet",
    	message: "Need all the names."
	}
}

export default CallDialog

export {Exceptions}