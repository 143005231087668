import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import session from './modules/session'
import checkIn from './modules/checkIn'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  // modules: ['session'],
  reducer: (state) => {
    // console.log('reducer', state)
    return {
      session: state.session,
      checkIn: {
        reservation: state.checkIn.reservation
      }
    }

    return state

  }
})

export default new Vuex.Store({
  modules: {
    session,
    checkIn
  },
  plugins: [vuexLocal.plugin]
})